<template>
    <div>
        <div
            v-if="permissionsLoaded && (inAdmin || hasPolicySystemAccess)"
            id="app"
        >
            <RouterView
                :mode="mode"
                @toggle-mode="toggleMode($event)"
            />
        </div>

        <PSForbidden
            v-else-if="permissionsLoaded && !hasPolicySystemAccess"
            :can-impersonate="canImpersonate"
            :is-impersonating="isImpersonating"
        />

        <Loading
            v-else-if="!inAdmin"
            active
            loader=""
            class="cms-loading-component"
        >
            <FontAwesomeIcon
                icon="spinner"
                size="5x"
                pulse
                class="mb-2"
            />

            <template
                slot="after"
            >
                <h3
                    class="text-muted"
                >
                    loading...
                </h3>
            </template>
        </Loading>
    </div>
</template>

<script>
    import modesMixin from '@imt/vue-kit-car/src/mixins/modes';

    import authMixin from '@/mixins/auth';

    export default {
        name: 'App',
        components: {
            PSForbidden: () => import('@/components/errors/PSForbidden.vue'),
            Loading: () => import('vue-loading-overlay'),
        },
        mixins: [
            authMixin,
            modesMixin,
        ],
        async mounted() {
            this.$root.$on('bv::popover::shown', $evt => {
                if ($evt.relatedTarget.id === 'toolTipContainer') {
                    this.$gtag.event('Popover', 'error popover', $evt.relatedTarget.innerText);
                } else {
                    this.$gtag.event('Popover', 'Hover', $evt.target.id);
                }
            });

            this.$root.$on('bv::tooltip::shown', $evt => {
                this.$gtag.event('Tooltip', 'Hover', $evt.relatedTarget.textContent);
            });
        },
    };
</script>

<style lang="sass">
    @import "~@imt/vue-kit-car/src/sass/kit-car"
    @import "~@imt/vue-kit-car/src/sass/modules/multiselect"
    @import "./sass/modules/dropzones"

    .cms-loading-component
        width: 100vw
        height: 100vh
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column
        font-family: Arial, Helvetica, sans-serif

        .vld-icon
            display: flex
            justify-content: center
            align-items: center
            flex-direction: column
</style>
