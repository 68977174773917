export default {
    helpWording: {
        action_bar: {
            adding: {
                title: 'This is the title of the action bar. This will appear when selecting an action bar to display on a page.',
                description: 'A brief summary of the purpose of this action bar.',
                set: 'Specifies which set this action bar is related to.',
                task: 'Specifies which task this action bar should be added as a related item to.',
            }
        },
        action_item: {
            adding: {
                title: 'This is the title of the action item. This is what will appear as the label of the button.',
                type: 'The type of the action item.',
                link: 'The url this action item should link to.',
                set: 'Specifies which set this action item is related to.',
                task: 'Specifies which task this action item should be added as a Related Item to.',
                internal: 'Sets visible only to internal users.',
                opens_new_tab: 'Sets if link opens in new tab.',
                predefined: 'Creates a duplicate predefined item for later use',
            }
        },
        pages: {
            adding: {
                title: 'This is the title of the page that will be displayed in the policy system.',
                description: 'A brief summary of the page contents and intended use.',
                navigation: 'This will add a link to the page in the navigation.',
                page_menus: 'The page menu that will display on this page in the policy system.',
                predefined: 'If Yes is selected, a predefined layout will be used for the page. If No is selected, the layout will need to be created.',
                set: 'Specifies which set this page is related to.',
                task: 'Specifies which task this page should be added as a Related Item to.',
                transaction_types: 'This will ensure that the page will only display on policies with the given transaction types.',
            }
        },
        policy_systems: {
            adding: {
                baseCommissionRate: 'Base commission rate is the default percent of premium paid to the agency for policies. This can be overridden by business rules.',
                boilerplate: 'Automatically sets up the items common to every policy system for you, such as navigation, pages, and action bars. If the boilerplate is not used, the policy system will need to be set up.',
                company: 'Specifies which company this policy system is for.',
                defaultLineCode: 'Downstream IMT systems rely on a Line Code, a 3-digit numeric identifier for a line of business. Each policy system should have a unique line code.',
                description: 'A brief summary of the policy system and use.',
                logo: 'This will be the image used to represent the organization or company to be displayed within the policy system. If no image is supplied, the company logo will be defaulted as the logo.',
                policyPrefix: 'This is the prefix that will be used in the policy number.',
                policyLine: 'This indicates if the policy line is commercial or personal',
                askKodiakGroup: 'This is the alphanumeric identifier used for the corresponding Ask Kodiak Taxonomy that is currently available',
                quotePrefix: 'This is the prefix that will be used in the quote number.',
                ratingIdentifier: 'The "KeyName" that is used when calling WTW (Willis Towers Watson) rating service',
                set: 'Specifies which set this policy system is related to.',
                title: 'This is the title of and will be displayed in the policy system.',
                task: 'Specifies which task this policy system should be added as a Related Item to.',
                url: 'This is the web address used to view the policy system.',
            },
            defaultLogoMessage(companyName) {
                return `${companyName}'s logo will be used by default unless one is provided here.`;
            }
        },
        navigation: {
            adding: {
                type: 'This is the type of navigation item to add. Each available type is explained below:',
                types: {
                    page: 'A page within the policy system.',
                    link: 'A custom web address to link to.',
                    dropdown: 'A dropdown list of other navigation items to help group things together.'
                },
                page: 'This is a page this navigation item will link to.',
                title: 'This is the title that will display on this navigation item.',
                link: 'This is the web address this navigation item will link to.',
                link_opens_new_tab: 'If Yes is selected, the linked URL will open in a new browser tab. If No is selected, the linked URL will replace what\'s in the current browser tab.',
                on_pages: 'This is the list of pages that the current page menu will appear on. Adding a page to this list will unlink any other page menus currently displaying on that page.'
            }
        },
        question_groups: {
            adding: {
                title: 'This is the title of the question group that will be displayed in the policy system.',
                description: 'A brief summary of the question group contents and intended use.',
                displays: 'This is how the question group will be displayed in the policy system.',
                level: 'This is the level at which the question group will be displayed in the policy system.',
                set: 'Specifies which set this question group is related to.',
                task: 'Specifies which task this question group should be added as a Related Item to.',
            }
        },
    },
    tooltips: {
        actions: {
            edit_policy_system: 'Edit Policy System',
            view_policy_system: 'View Policy System',
            edit_page: 'Edit Page',
            view_page: 'View Page',
            view_policy: 'View Policy',
            edit_action_bar: 'Edit Action Bar',
            view_action_bar: 'View Action Bar',
            options: 'Options',
            revert: 'Revert',
            unschedule_revert: 'Unschedule Revert',
            duplicate: 'Duplicate Policy',
            cancel: 'Cancel Policy'
        },
        search_field_names: {
            search: 'Text Search',
            imt_company_id: 'Company',
            updated_by: 'Modified By',
            processing_status: 'Processing Status',
            coverage_status: 'Coverage Status',
            nav_pages: 'Navigation Pages'
        },
        versioning: {
            published: 'Published',
            not_published: 'Not yet published',
            scheduled: 'Scheduled to be published',
            scheduled_for_revert: 'Scheduled to be reverted',
            reverted: 'Reverted',
        },
        policy_systems: {
            versioning: {
                live: 'Production',
                not_live: 'Test',
                scheduled_live: 'Scheduled to be available in production'
            },
        },
    },
    errorMessages: {
        activity: {
            no_recent: 'No recent activity found.',
            no_your_recent: 'You don\'t have any recent activity.',
            unable_to_connect: 'Unable to connect to Activity Center.',
            unable_to_fetch: 'Unable to fetch activity.',
            unable_to_fetch_your_recent: 'Unable to fetch your recent activity.'
        },
        policy_system: {
            failed_to_load: 'Failed to load policy system.',
            file_uploaded_error: 'File upload was unsuccessful.'
        },
        navigation_item: {
            failed_to_delete: 'Failed to delete navigation item.',
            failed_to_update: 'Failed to update navigation item.',
        }
    },
    mergeTableReadableText: {
        questionGroup: 'Question Group',
        displays: 'Displays',
        level: 'Level',
        page: 'Page',
        actionBar: 'Action Bar',
        title: 'Title',
        description: 'Description',
        transactionTypes: 'Transaction Type(s)',
        webForm: 'Web Form',
        heading: 'Heading',
        webFormType: 'Web Form Type',
        sectionType: 'Section Type',
        inputType: 'Input Type',
        sections: 'Sections',
        column: 'Column',
        inputs: 'Inputs',
        name: 'Name',
        position: 'Position',
        repeatLimit: 'Repeat Limit',
        repeats: 'Repeats',
        tableName: 'Table Name',
        builtFrom: 'Built From',
        fieldName: 'Field Name',
        label: 'Label',
        maxLength: 'Maximum Length',
        maxSelections: 'Maximum Selections',
        minLength: 'Minimum Length',
        options: 'Options',
        placeholder: 'Placeholder',
        required: 'Required',
        dynamicOptions: 'Dynamic Options',
        width: 'Width',
        isDefault: 'Is Default',
        isDivider: 'Is Divider',
        value: 'Value',
        source: 'Source',
        customStyling: 'Custom Styling',
    },
    searchParams: {
        search: 'filter[text]',
        policy_system_id: 'filter[policy_system_id]',
        last_name: 'filter[last_name]',
        business_name: 'filter[business_name]',
        first_name: 'filter[first_name]',
        number: 'filter[number]',
        account_number: 'filter[account_number]',
        agency: 'filter[agency]',
        street_line_1: 'filter[street_line_1]',
        street_line_2: 'filter[street_line_2]',
        zipcode: 'filter[zipcode]',
        state: 'filter[state]',
        transaction_type: 'filter[transaction_type]',
        processing_status: 'filter[processing_status]',
        coverage_status: 'filter[coverage_status]',
        term_effective_date__gte: 'filter[term_effective_date_gte]',
        term_effective_date__lte: 'filter[term_effective_date_lte]',
        term_expiration_date__gte: 'filter[term_expiration_date_gte]',
        term_expiration_date__lte: 'filter[term_expiration_date_lte]',
        imt_company_id: 'filter[imt_company_id]',
        updated_by: 'filter[updated_by]',
    }
};

export function notInProductionEnv() {
    return process.env.NODE_ENV !== 'production';
}

export class CrossValidationError {
    constructor(rule, action, policyVersionAccommodations) {
        const savedAccommodation = policyVersionAccommodations[rule.ruleId];

        this.rule = rule;
        this.ruleId = rule.ruleId;
        this.accommodationStatus = savedAccommodation?.status || null;
        this.savedAccommodation = savedAccommodation;
        this.componentSyncId = action.componentSyncId;
        this.canAccommodate = rule.canAccommodate;
        this.message = action.message;
        this.helpWordingText = rule.helpWordingText;
        this.pageId = action.pageId;
        this.type = (() => {
            if (rule.canAccommodate && rule.underwritingFix) {
                return 'underwriterAccommodation';
            } else if (rule.canAccommodate && !rule.underwritingFix) {
                return 'agentAccommodation';
            } else {
                return 'displayOnly'; // Error can't be accommodated, just display it in the error panel
            }
        })();
    }
}

export function scrollToName(componentSyncId) {
    const element = document.querySelector(`[name='${componentSyncId}']`);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });

        return true;
    }

    return false;
}
