import {UUID_REGEX} from '@imt/vue-toolbox/src/utils';
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/admin',
            component: () => import(/* webpackChunkName: "CMSAdmin" */ '@/views/CMSAdmin.vue'),
            redirect: '/admin/policy-systems',
            meta: {
                title: 'admin',
            },
            children: [
                {
                    path: '/',
                    redirect: '/admin/policy-systems',
                },
                {
                    // TODO: replace this with a real client view or link to IMTOnline once implemented.
                    path: 'fake-client',
                    name: 'fake-client',
                    component: () => import(/* webpackChunkName: "CMSFakeClient" */'@/fake_client/CMSFakeClient.vue'),
                    meta: {
                        title: 'Fake Client',
                        menu: 'platform-admin',
                    },
                },
                {
                    path: 'policy-systems',
                    name: 'admin.policy-system.list',
                    component: () => import(/* webpackChunkName: "CMSPolicySystems" */ '@/views/admin/CMSPolicySystems.vue'),
                    meta: {
                        title: 'Policy Systems',
                        menu: 'platform-admin',
                    },
                    children: [
                        {
                            path: 'new',
                            name: 'admin.policy-system.add',
                            component: () => import(/* webpackChunkName: "CMSPolicySystemAddModal" */ '@/components/policy_systems/CMSPolicySystemAddModal.vue'),
                            meta: {
                                title: 'Policy Systems',
                                menu: 'platform-admin',
                            },
                        },
                        {
                            path: `:policySystemOptionsId(${UUID_REGEX})`,
                            name: 'admin.policy-system.edit',
                            component: () => import(/* webpackChunkName: "CMSPolicySystemOptionsModal" */ '@/components/policy_systems/CMSPolicySystemOptionsModal.vue'),
                            meta: {
                                title: 'Policy Systems',
                                menu: 'platform-admin',
                            },
                        },
                    ],
                },
                {
                    path: `policy-systems/:policySystemId(${UUID_REGEX})/edit`,
                    component: () => import(/* webpackChunkName: "CMSPolicySystemAdmin" */ '@/views/admin/CMSPolicySystemAdmin.vue'),
                    children: [
                        {
                            path: '/',
                            name: 'admin.policy-system.dashboard',
                            component: () => import(/* webpackChunkName: "CMSPolicySystemDashboard" */ './views/admin/CMSPolicySystemDashboard.vue'),
                            meta: {
                                title: 'Dashboard',
                                menu: 'cms',
                                setsDocumentTitle: true,
                            },
                        },

                        // Pages
                        {
                            path: 'pages',
                            name: 'admin.policy-system.page.list',
                            component: () => import(/* webpackChunkName: "CMSPages" */ './views/admin/CMSPages.vue'),
                            meta: {
                                title: 'Pages',
                                menu: 'cms',
                                setsDocumentTitle: true,
                            },
                            children: [
                                {
                                    path: 'new',
                                    name: 'admin.policy-system.page.add',
                                    component: () => import(/* webpackChunkName: "CMSPageAddModal" */ './components/pages/CMSPageAddModal.vue'),
                                    meta: {
                                        title: 'Pages',
                                        menu: 'cms',
                                    },
                                },
                                {
                                    path: `:pageId(${UUID_REGEX})`,
                                    name: 'admin.policy-system.page.options',
                                    component: () => import(/* webpackChunkName: "CMSPageOptionsModal" */ './components/pages/CMSPageOptionsModal.vue'),
                                    meta: {
                                        title: 'Pages',
                                        menu: 'cms',
                                    },
                                },
                            ],
                        },
                        {
                            path: `pages/:pageId(${UUID_REGEX})/edit/:versionId(${UUID_REGEX})`,
                            name: 'admin.policy-system.page.edit',
                            component: () => import(/* webpackChunkName: "CMSWebFormEditor" */ './views/admin/CMSWebFormEditor.vue'),
                            meta: {
                                title: 'Editing Page',
                                menu: 'cms',
                                hideFooter: true,
                            },
                        },
                        {
                            path: `pages/:pageId(${UUID_REGEX})/preview/:versionId(${UUID_REGEX})`,
                            name: 'admin.policy-system.page.preview',
                            component: () => import(/* webpackChunkName: "VersioningViewForm" */ '@imt/vue-versioning/src/views/ViewForm.vue'),
                            meta: {
                                title: 'Page Preview',
                                menu: 'cms',
                            },
                        },
                        {
                            path: `pages/:pageId(${UUID_REGEX})/view/:versionId(${UUID_REGEX})`,
                            name: 'admin.policy-system.page.view',
                            component: () => import(/* webpackChunkName: "CMSWebFormView" */ './views/admin/CMSWebFormView.vue'),
                            meta: {
                                title: 'View Page',
                                menu: 'cms',
                            },
                        },

                        // Question Groups
                        // TODO: remove routes and components in https://gitlab.imtins.com/imt-platform/services/cms/-/issues/718
                        // {
                        //     path: 'question-groups',
                        //     name: 'admin.policy-system.question-group.list',
                        //     component: () => import(/* webpackChunkName: "CMSQuestionGroups" */ './views/admin/CMSQuestionGroups.vue'),
                        //     meta: {
                        //         title: 'Question Groups',
                        //         menu: 'cms',
                        //         setsDocumentTitle: true,
                        //     },
                        //     children: [
                        //         {
                        //             path: 'new',
                        //             name: 'admin.policy-system.question-group.add',
                        //             component: () => import(/* webpackChunkName: "CMSQuestionGroupAddModal" */ './components/question_groups/CMSQuestionGroupAddModal.vue'),
                        //             meta: {
                        //                 title: 'Question Groups',
                        //                 menu: 'cms',
                        //             },
                        //         },
                        //         {
                        //             path: `:questionGroupId(${UUID_REGEX})`,
                        //             name: 'admin.policy-system.question-group.options',
                        //             component: () => import(/* webpackChunkName: "CMSQuestionGroupOptionsModal" */ './components/question_groups/CMSQuestionGroupOptionsModal.vue'),
                        //             meta: {
                        //                 title: 'Question Groups',
                        //                 menu: 'cms',
                        //             },
                        //         },
                        //     ],
                        // },
                        // {
                        //     path: `question-groups/:questionGroupId(${UUID_REGEX})/edit/:versionId(${UUID_REGEX})`,
                        //     name: 'admin.policy-system.question-group.edit',
                        //     component: () => import(/* webpackChunkName: "CMSWebFormEditor" */ './views/admin/CMSWebFormEditor.vue'),
                        //     meta: {
                        //         title: 'Editing Question Group',
                        //         menu: 'cms',
                        //         hideFooter: true,
                        //     },
                        // },
                        // {
                        //     path: `question-groups/:questionGroupId(${UUID_REGEX})/view/:versionId(${UUID_REGEX})`,
                        //     name: 'admin.policy-system.question-group.view',
                        //     component: () => import(/* webpackChunkName: "CMSWebFormView" */ './views/admin/CMSWebFormView.vue'),
                        //     meta: {
                        //         title: 'View Question Group',
                        //         menu: 'cms',
                        //     },
                        // },
                        // {
                        //     path: `question-groups/:questionGroupId(${UUID_REGEX})/preview/:versionId(${UUID_REGEX})`,
                        //     name: 'admin.policy-system.question-group.preview',
                        //     component: () => import(/* webpackChunkName: "VersioningViewForm" */ '@imt/vue-versioning/src/views/ViewForm.vue'),
                        //     meta: {
                        //         title: 'Question Group Preview',
                        //         menu: 'cms',
                        //     },
                        // },

                        // Data Conflicts
                        {
                            path: '/merge',
                            name: 'merge',
                            component: () => import(/* webpackChunkName: "CMSDataConflicts" */ './views/admin/CMSDataConflicts.vue'),
                            meta: {
                                title: 'Data Conflicts',
                                menu: 'cms',
                            },
                        },
                        // {
                        //     path: `question-groups/:questionGroupsId(${UUID_REGEX})/merge`,
                        //     name: 'policy-system.question-group.merge',
                        //     component: () => import(/* webpackChunkName: "CMSDataConflicts" */ './views/admin/CMSDataConflicts.vue'),
                        //     meta: {
                        //         title: 'Question Group Data Conflicts',
                        //         menu: 'cms',
                        //     },
                        // },
                        {
                            path: `pages/:pageId(${UUID_REGEX})/merge`,
                            name: 'policy-system.page.merge',
                            component: () => import(/* webpackChunkName: "CMSDataConflicts" */ './views/admin/CMSDataConflicts.vue'),
                            meta: {
                                title: 'Page Data Conflicts',
                                menu: 'cms',
                            },
                        },

                        // Navigation
                        {
                            path: 'navigation',
                            name: 'admin.policy-system.navigation',
                            component: () => import(/* webpackChunkName: "CMSMainNavigationAdmin" */ './views/admin/CMSMainNavigationAdmin.vue'),
                            meta: {
                                title: 'Main Navigation',
                                menu: 'cms',
                                setsDocumentTitle: true,
                            },
                        },

                        // Action Bars
                        {
                            path: 'action-bars',
                            name: 'admin.policy-system.action-bar.list',
                            component: () => import(/* webpackChunkName: "CMSActionBars" */ './views/admin/CMSActionBars.vue'),
                            meta: {
                                title: 'Action Bars',
                                menu: 'cms',
                                setsDocumentTitle: true,
                            },
                            children: [
                                {
                                    path: 'new',
                                    name: 'admin.policy-system.action-bar.add',
                                    component: () => import(/* webpackChunkName: "CMSActionBarAddModal" */ './components/action_bars/CMSActionBarAddModal.vue'),
                                    meta: {
                                        title: 'Action Bars',
                                        menu: 'cms',
                                    },
                                },
                                {
                                    path: `:actionBarId(${UUID_REGEX})`,
                                    name: 'admin.policy-system.action-bar.options',
                                    component: () => import(/* webpackChunkName: "CMSActionBarOptionsModal" */ './components/action_bars/CMSActionBarOptionsModal.vue'),
                                    meta: {
                                        title: 'Action Bars',
                                        menu: 'cms',
                                    },
                                },
                            ],
                        },
                        {
                            path: `action-bars/:actionBarId(${UUID_REGEX})/edit`,
                            name: 'admin.policy-system.action-bar.edit',
                            component: () => import(/* webpackChunkName: "CMSActionBarAdmin" */ './views/admin/CMSActionBarAdmin.vue'),
                            meta: {
                                title: 'Edit Action Bar',
                                menu: 'cms',
                                setsDocumentTitle: true,
                            },
                        },
                    ],
                },
                {
                    path: 'configurations',
                    name: 'admin.configurations',
                    meta: {
                        title: 'Application Configuration',
                    },
                    component: () => import(/* webpackChunkName: "CMSConfigurations" */ './views/admin/CMSConfigurations.vue'),
                },
                // Fallback
                {
                    path: '*',
                    name: 'admin.not-found',
                    component: () => import(/* webpackChunkName: "KCNotFound" */ '@imt/vue-kit-car/src/components/errors/NotFound.vue'),
                    meta: {
                        title: 'Not Found',
                        menu: 'platform-admin',
                    },
                },
            ]
        },

        // Fallback
        {
            path: '/',
            name: 'not-found',
            component: () => import(/* webpackChunkName: "PSNotFound" */ '@/components/errors/PSNotFound.vue'),
            meta: {
                title: 'Not Found',
            },
        },
        {
            path: '*',
            name: 'Not Found',
            component: () => import(/* webpackChunkName: "PSNotFound" */ '@/components/errors/PSNotFound.vue'),
            meta: {
                title: 'Not Found',
            },
        },

        // Policy System Front End
        {
            path: `/:url/:pageId(${UUID_REGEX})`,
            name: 'policy-system.front-end',
            component: () => import(/* webpackChunkName: "PSPolicySystem" */ './views/policy_system_frontend/PSPolicySystem.vue'),
            meta: {
                setsDocumentTitle: true,
                scrollToTop: true,
            },
        },
        {
            path: '/:url?/',
            name: 'policy-system.front-end.landing',
            component: () => import(/* webpackChunkName: "PSPolicySystem" */ './views/policy_system_frontend/PSPolicySystem.vue'),
            meta: {
                setsDocumentTitle: true,
                scrollToTop: true,
            },
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (!to.meta.setsDocumentTitle) {
        document.title = `${to.meta.title} | IMT Platform`;
    }

    next();
});

export default router;
