import {Integrations} from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import Vue from 'vue';

const ENV = process.env.VUE_APP_SENTRY_ENV || process.env.NODE_ENV;

if (!['development', 'test'].includes(ENV) && !window.Cypress) {
    Sentry.init({
        Vue,
        dsn: 'https://d7483f8b639e4ee6b2d5333abf710329@o527262.ingest.sentry.io/5658863',
        environment: ENV || 'production',
        logErrors: ENV === 'staging',
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        tracesSampleRate: 1.0,
        tracingOptions: {
            trackComponents: true,
        },
    });
}
