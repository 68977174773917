import auth from '@imt/vue-toolbox/src/mixins/auth';
import {mapState} from 'vuex';

export default {
    computed: {
        canImpersonate() {
            return !!this.permissions.can_impersonate_user;
        },
        canManage() {
            return !!this.permissions.policy_system_manage;
        },
        canManageConfigurations() {
            return this.permissions.configuration_manage;
        },
        canMigrate() {
            return this.permissions.policy_system_migrate;
        },
        canVersionManage() {
            return !!this.permissions.versioning_manage;
        },
        hasAccess() {
            return !!this.permissions.cms_access;
        },
        hasConfigurationAccess() {
            return this.permissions.configuration_access;
        },
        hasInternalAccess() {
            return !!this.permissions.policy_system_internal;
        },
        hasPolicySystemAccess() {
            return !!this.permissions.policy_system_access;
        },
        inAdmin() {
            return this.$route.path.includes('admin');
        },
        isImpersonating() {
            return !!this.user.impersonatedId;
        },
        permissionsLoaded() {
            return !!this.user.id;
        },
        ...mapState('toolbox', [
            'user',
            'permissions',
        ]),
    },
    mixins: [auth]
};
