import {asString, defaultParamMap} from '@imt/vue-list/src/utils/queryParams';
import utils, {axiosWithAuth} from '@imt/vue-toolbox/src/utils';
import Vue from 'vue';

import wording from '@/utils';

export const authedAxios = axiosWithAuth();

export const actions = {
    async duplicatePolicy(_, {
        data,
        id,
    }) {
        const url = `${process.env.VUE_APP_POLICY_DATA_URL}/api/v1/policies/${id}/duplicate-as-quote/?include=versions&fields[Version]=policy_system_id`,
            response = await authedAxios.post(url, utils.dataFormatter.serialize({stuff: {type: 'Policy', ...data}}));

        return utils.dataFormatter.deserialize(response.data);
    },
    async createPolicy(_, {data, snapshotData}) {
        const groupResponse = await authedAxios.post(
                `${process.env.VUE_APP_POLICY_DATA_URL}/api/v1/policy-groups/`,
                utils.dataFormatter.serialize({stuff: snapshotData})
            ),
            groupDeserialized = utils.dataFormatter.deserialize(groupResponse.data),
            response = await authedAxios.post(
                `${process.env.VUE_APP_POLICY_DATA_URL}/api/v1/data/?include=versions`,
                utils.dataFormatter.serialize({
                    stuff: {
                        ...data,
                        groupId: groupDeserialized.id,
                    },
                    includeNames: [
                        'versions',
                        'versions.entity',
                        'versions.updated_by',
                        'versions.created_by',
                    ],
                }),
                // This is request is made as application/json rather than JSONAPI, even though the data is formatted as such, because
                // saving multiple models (as we are doing with the `includes` key) is out of spec. Our API plugin attempts to correct
                // our request data and we lose data. application/json accepts the data as is
                {headers: {'Content-Type': 'application/json'}},
            );

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchPolicies({commit}, queryObject) {
        let queryString = asString(queryObject, {
                ...defaultParamMap,
                ...wording.searchParams,
            }),
            url = `/api/v1/policies/?include=versions&fields[Version]=state,processing_status,coverage_status,transaction_type,term_effective_date,policy_system_id`,
            response = await authedAxios.get(`${process.env.VUE_APP_POLICY_DATA_URL}${url}${queryString}`);

        commit('SET_DATA', {
            field: 'policies',
            data: utils.dataFormatter.deserialize(response.data),
        });

        commit('SET_DATA', {field: 'totalPolicyCount', data: response.data.meta.pagination.count});

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchPolicyById(context, policyId) {
        let url = `/api/v1/policies/${policyId}?include=versions`,
            response = await authedAxios.get(`${process.env.VUE_APP_POLICY_DATA_URL}${url}`);

        return utils.dataFormatter.deserialize(response.data);
    },
};

const mutations = {
    ADD_VERSION(state, {
        policy,
        version,
    }) {
        let statePolicy = state.policies.find(p => p.id === policy.id);
        statePolicy.versions.push(version);
    },
    SET_DATA(state, {field, data}) {
        state[field] = data;
    },
    CLEAR_SEARCH_FIELDS(state) {
        state.searchFields = {};
    },
    SET_SEARCH_FIELDS(state, searchField) {
        if ((typeof searchField.value !== 'boolean' && !searchField.value) || (Array.isArray(searchField.value) && !searchField.value.length)) {
            Vue.delete(state.searchFields, searchField.key);
        } else {
            Vue.set(state.searchFields, searchField.key, searchField.value);
        }
    },
    REMOVE_VERSION(state, {
        policy,
        versionId,
    }) {
        let statePolicy = state.policies.find(p => p.id === policy.id);

        Vue.delete(statePolicy.versions, statePolicy.versions.findIndex(v => v.id === versionId));
    },
};


export const state = () => {
    return {
        policies: [],
        totalPolicyCount: 0,
        searchFields: {},
    };
};

export default {
    actions,
    getters: {},
    mutations,
    namespaced: true,
    state: state(),
};
