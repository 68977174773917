import {pathValidator} from '@imt/vue-spotme/src/plugins/dot-notation';
import validateLabel from '@imt/vue-web-form-editor/src/plugins/vee-validate';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {extend} from 'vee-validate';
import {email, excluded, max, max_value, min_value, numeric, regex, required} from 'vee-validate/dist/rules'; // eslint-disable-line camelcase
import Vue from 'vue';

extend('email', email);
extend('excluded', excluded);
extend('policy_data_path', pathValidator);
extend('max', max);
extend('max_value', max_value);
extend('min_value', min_value);
extend('numeric', numeric);
extend('regex', regex);
extend(validateLabel.name, validateLabel.validator);

extend('max', {
    ...max,
    params: ['length'],
    message: 'This field must be less than {length} characters'
});

extend('required', {
    ...required,
    message: 'This field is required'
});

extend('numeric', {
    ...numeric,
    message: 'This field can only contain numeric values'
});

extend('uniq', (value, [uniqFieldName, uniqValue]) => {
    return value.toUpperCase() === uniqValue.toUpperCase() ? `This field and ${uniqFieldName} must be unique` : true;
});

extend('no_admin',
    {
        message: 'Value cannot contain "admin".',
        validate(value) {
            return !value.includes('admin');
        },
    }
);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
