import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faBoxesAlt,
    faCaretUp,
    faCog,
    faCube,
    faEye,
    faFloppyDisk,
    faInfoCircle,
    faPen,
    faPlus,
    faSearch,
    faSpinner,
    faTimes,
    faTrashAlt,
} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

library.add(
    faBoxesAlt,
    faCaretUp,
    faCog,
    faCube,
    faEye,
    faFloppyDisk,
    faInfoCircle,
    faPen,
    faPlus,
    faSearch,
    faSpinner,
    faTimes,
    faTrashAlt,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
